import React from 'react'

const Button = ({
  type = "button",
  variant = "primary",
  children,
  handleClick = null,
  fullWidth = false,
  size = 'md',
  className = '',
}) => {
  const getClassNames = () => {
    switch(variant){
      case "primary":
        return "bg-dark-blue after:bg-normal-blue before:bg-normal-blue text-white"
      case "secondary":
        return "bg-white border border-dark-blue after:bg-dark-blue before:bg-dark-blue text-dark-blue hover:text-white"
      case "tertiary":
        return "bg-[#65B1A1] after:bg-normal-blue before:bg-normal-blue text-white"
      default:
        return null
    }
  }
  return (
    <button
      type={type}
      onClick={handleClick}
      className={`${
        fullWidth && 'w-full'
      } relative flex justify-center items-center gap-2  ${
        size === 'md'
          ? 'px-6 py-3 md:px-8 md:py-4 text-sm md:text-base'
          : size === 'sm'
          ? 'px-4 py-2 md:px-6 md:py-3 text-[0.8rem] md:text-sm'
          : ''
      } ${getClassNames()} rounded-lg ${className} overflow-hidden
        after:absolute after:top-0 after:-translate-x-full hover:after:translate-x-0 after:left-0 after:w-1/2 after:h-full after:transition-transform after:duration-500 after:ease-in-out
        before:absolute before:top-0 before:translate-x-full hover:before:translate-x-0 before:right-0 before:w-1/2 before:h-full before:transition-transform before:duration-500 before:ease-in-out
        transition-colors duration-300`}
    >
      <p className='text-sm md:text-base font-semibold z-10'>{children}</p>
    </button>
  )
}

export default Button

import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

import gambar from "../../images/career/bannerCareer.png"

import Button from '../common/Button'

const CareerBanner = ({title, description}) => {
  const {t} = useTranslation()
  return (
    <section className="flex flex-col lg:flex-row justify-between items-center gap-8 bg-white">
      <div className='w-1/2 h-full object-contain'>
        <img src={gambar} alt={title} className='w-full h-full object-contain object-top' style={{aspectRatio: "4/3"}}/>
      </div>
      <div className="flex flex-col gap-4 w-full md:w-2/3 lg:w-1/2 text-center items-center lg:items-start px-6 md:px-8 lg:px-4">
        <div className='flex flex-row gap-4 mb-4 lg:mb-8 items-center lg:items-start'>
          <div className="w-20 md:w-24 h-3 md:h-4 bg-[#2F4375] rounded-full"></div>
          <div className="w-20 md:w-24 h-3 md:h-4 bg-[#07698C] rounded-full"></div>
          <div className="w-12 md:w-16 h-3 md:h-4 bg-[#65B1A1] rounded-full"></div>
        </div>
        <h2 className="text-2xl md:text-3xl lg:text-4xl text-dark-blue lg:text-start">{title}</h2>
        <p className="text-sm md:text-base text-dark-blue/80 lg:text-start">{description}</p>
        <Link to="#jobs">
          <Button className='w-full rounded-full' variant='tertiary' size='sm'>{t('See Available Position')}</Button>
        </Link>
      </div>
    </section>
  )
}

export default CareerBanner

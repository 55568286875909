import React from "react"

import { Seo as SEO } from '../../components/Seo'
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import Layout from '../../components/layout/Layout'

import trust from "../../images/career/pancasradha/trust.png"
import mindfullnes from "../../images/career/pancasradha/mindfullness.png"
import innovation from "../../images/career/pancasradha/innovation.png"
import strive from "../../images/career/pancasradha/strive.png"
import interconnected from "../../images/career/pancasradha/Interconnectedness.png"

import CareerBanner from "../../components/career/CareerBanner"
const CareerCarouselSection = React.lazy(() => import('../../components/career/CareerCarouselSection'))
const PancaSradhaSection = React.lazy(() => import('../../components/career/PancaSradhaSection'))
const StoriesSection = React.lazy(() => import("../../components/career/StoriesSection"))
const PerksSection = React.lazy(() => import('../../components/career/PerksSection'))
const SaySection = React.lazy(() => import('../../components/career/SaySection'))
const HowJoinSection = React.lazy(() => import('../../components/career/HowJoinSection'))
const JobSection = React.lazy(() => import('../../components/career/JobSection'))

const CareerPage = ({data}) => {
  const {t} = useTranslation();

  const pancasradhaContent = [
    {
      image: trust,
      title: t('Panca Head 1'),
      description: t('Panca Text 1')
    },
    {
      image: mindfullnes,
      title: t('Panca Head 2'),
      description: t('Panca Text 2'),
    },
    {
      image: innovation,
      title: t('Panca Head 3'),
      description: t('Panca Text 3'),
    },
    {
      image: strive,
      title: t('Panca Head 4'),
      description: t('Panca Text 4'),
    },
    {
      image: interconnected,
      title: t('Panca Head 5'),
      description: t('Panca Text 5'),
    },
  ]

  const careerImages = data.careerImages.nodes
  const perkContent = data.perks.nodes
  const saysContent = data.says.nodes
  const jobsContent = data.jobs.nodes

  return(
    <Layout pathname="/career">
      <CareerBanner 
        title={t('Discover Header')}
        description={t('Discover Text')}
      />
      <CareerCarouselSection careerImages={careerImages}/>
      <PancaSradhaSection pancasradhas={pancasradhaContent}/>
      <StoriesSection stories={data.stories.nodes} categories={data.storyTypes.nodes}/>
      <PerksSection perks={perkContent}/>
      <SaySection says={saysContent}/>
      <HowJoinSection/>
      <JobSection jobs={jobsContent} categories={data.categories.nodes}/>
    </Layout>
  )
}

export default CareerPage

export const Head = () => {
    return (
      <SEO
        title={'Bifarma Adiluhung | Career'}
        description={
          'Join Bifarma Adiluhung and contribute to our mission of enhancing lives through preventive, promotive, and predictive treatments. Explore rewarding career opportunities in the dynamic biopharmaceutical sector.'
        }
      />
    )
}

export const query = graphql`
  query ($language: String!, $locale: String) {
    locales: allLocale(
      filter: { ns: { in: ["common", "career"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    careers: allContentfulCareerDashboard(
      filter: {node_locale: {glob: $locale}}
    ) {
      nodes {
        image {
          gatsbyImageData
        }
        title
        description {
          description
        }
      }
    }
    careerImages: allContentfulCareerCarousel(
      filter: {node_locale: {glob: $locale}}
      sort: {order: ASC, fields: order}
    ) {
      nodes {
        title
        image {
          gatsbyImageData
        }
      }
    }
    stories: allContentfulBifarmaStories(filter: {node_locale: {glob: $locale}}) {
      nodes {
        title
        image{
          gatsbyImageData(width: 1280, height: 960)
        }
        bifarmaStoriesType {
          type
        }
      }
    }
    storyTypes: allContentfulBifarmaStoriesType(sort: {fields: order, order: ASC}, filter: {node_locale: {glob: $locale}}) {
      nodes {
        type
      }
    }
    says: allContentfulCareerTestimony(
      filter: {node_locale: {glob: $locale}}
    ) {
      nodes {
        image {
          gatsbyImageData
        }
        description {
          description
        }
        fullName
        position
      }
    }
    perks: allContentfulCareerPerks(
      filter: {node_locale: {glob: $locale}}
    ) {
      nodes {
        image {
          gatsbyImageData
        }
        title
        description {
          description
        }
      }
    }
    jobs: allContentfulJobPosition(
      filter: {node_locale: {glob: $locale}}
    ) {
      nodes {
        slug
        category{
          name
        }
        title
        level
        url
        location
      }
    }
    allJobs: allContentfulJobPosition(
        filter: { node_locale: { glob: $locale } }
    ) {
        nodes {
          slug
          title
        }
    }
    categories: allContentfulJobPositionCategory(
      filter: { node_locale: { glob: $locale } }
    ) {
      nodes {
        name
      }
    }
  }
`